.nav {
  max-width: 144rem;
}

.navList {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  gap: 2rem;
  text-transform: uppercase;
  padding-left: 20%;
  padding-top: 15%;
}

.navListItem__selected {
  color: var(--lightGreen);
}

.navListItem {
  font-size: 2.2rem;
  font-weight: 700;
  padding-bottom: 1rem;
  letter-spacing: 1px;
  position: relative;
  background-image: linear-gradient(rgb(114, 226, 174), rgb(114, 226, 174));
  background-size: 0% 4px;
  background-repeat: no-repeat;
  background-position-y: 38px;
  transition: all 0.5s ease;
}

.navListItem:hover {
  cursor: pointer;
  background-size: 100% 4px;
  transition: all 0.5s ease;
}

.number {
  padding-right: 1rem;
  color: var(--lightGreen);
  font-size: 2.2rem;
}

.active > li {
  color: var(--lightGreen);
  background-image: linear-gradient(rgb(114, 226, 174), rgb(114, 226, 174));
  background-size: 100% 4px;
  background-repeat: no-repeat;
  background-position-y: 38px;
  transition: all 0.5s ease;
  display: inline-block;
}

@media screen and (min-width: 425px) {
  .navListItem {
    font-size: 2.2rem;
    letter-spacing: 1px;
  }

  .number {
    padding-right: 1rem;
    font-size: 2.4rem;
  }
  .navList {
    gap: 4rem;
  }
}

@media screen and (min-width: 769px) {
  .navList {
    flex-direction: row;
    padding: 0;
  }
}
