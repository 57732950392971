.item {
    width: 100%;
    padding: 2rem;
    background-color: #353b48;
    border: 1px solid rgb(82, 81, 81);
    border-radius: 5px;
    flex-shrink: 0;
}

.itemUp {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: itemUp;
}

.text {
  font-size: 1.2rem;
  line-height: 2.4rem;
  color: var(--lightGreen);
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.imageOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

@keyframes itemUp {
  0%    { transform: translateY(0)}
  100%   { transform: translateY(-10px)}
}

.itemDown {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-name: itemDown;
}

@keyframes itemDown {
  0%    { transform: translateY(-10px)}
  100%   { transform: translateY(0)}
}


.imageContainer {
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
}

.title {
    font-size: 2.2rem;
    font-weight: 700;
    letter-spacing: 0.76px;
    padding-left: 1.5rem;
    margin-bottom: 1rem;
}

.list {
    display: flex;
    gap: 3rem;
    padding-left: 2.9rem;
}

.listItem {
    font-size: 1.4rem;
    position: relative;
}

.listItem::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: var(--lightGreen);
    position: absolute;
    top: 6px;
    left: -13px;
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: blip;
  }
  
  @keyframes blip {
    0%    { opacity: 0.5; }
    50%   { opacity: 1; }
    100%   { opacity: 0.5; }
  }

  .overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    backdrop-filter: blur(10px);
    background: hsla(0,0%,100%,.04);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    transform: translateX(-100%)
  }

  .overlayFadeIn {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-name: fadeIn;
  }

  @keyframes fadeIn {
    0%    { transform: translateX(-100%)}
    100%   { transform: translateX(0%)}
  }

  .overlayFadeOut {
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-name: fadeOut;
  }

  @keyframes fadeOut {
    0%    {  transform: translateX(0%)}
    100%   { transform: translateX(-100%)}
  }
  
  .iconContainer {
    width: 45px;
    height: 45px;
    border-radius: 100%;
    background-color: var(--lightGreen);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .iconImageOne {
    width: 60%;
  }

  .iconImageTwo {
    width: 45%;
  }

  .iconImage:hover {
    cursor: pointer;
  }