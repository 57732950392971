.mainHeader {
    font-size: 4rem;
    line-height: 2rem;
    color: var(--lightGreen);
    letter-spacing: 2px;
  }
  
  .mainHeader:hover {
    cursor: pointer;
  }
  
  .mainHeader__light {
    color: white;
  }

  @media screen and (min-width: 768px) {
    .mainHeader {
      padding-bottom: 1rem;
    }
  }