.contact {
  display: grid;
  padding: 0 2.4rem;
  margin-bottom: 8rem;
}

.form {
  width: 100%;
  max-width: 55rem;
  margin: auto;
  padding: 2rem;
  background-color: #353b48;
  border: 1px solid rgb(82, 81, 81);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  opacity: 0;
}

.formAnimation {
  animation-delay: 500ms;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: appear;
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


.formItem {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-weight: 700;
  font-size: 1.4rem;
}

.input {
  background-color: var(--backgroundMain);
  border: 1px solid rgb(82, 81, 81);
  border-radius: 4px;
  font-size: 1.4rem;
  padding: 1rem;
  font-weight: 500;
  color: white;
}

.input:focus {
  outline: 1px solid var(--lightGreen);
}

.textarea {
  font-family: "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.sendButton {
  background-color: var(--lightGreen);
  color: var(--backgroundMain);
  border: none;
  border-radius: 4px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.76px;
  width: 10rem;
  padding: 1.2rem 0;
  font-size: 1.4rem;
}

.sendButton:hover {
  cursor: pointer;
  background-color: rgb(148, 246, 201);
}

.errorMessage {
  text-align: center;
  color: rgb(239, 68, 68);
  background-color: rgba(239, 68, 68, 0.2);
  font-weight: 700;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding: 0.3rem 0 0.6rem;
  position: relative;
  top: -4px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.messageOK {
  color: rgb(23, 174, 23);
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.messageNOK {
  color: rgb(239, 68, 68);
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.5px;
}

@media screen and (min-width: 1440px) {
  .label,
  .input,
  .sendButton {
    font-size: 1.6rem;
  }

  .formItem {
    gap: 1rem;
  }

  .form {
    gap: 2.5rem;
  }
}
