:root {
  --backgroundMain: #282c34;
  --lightGreen: rgb(114, 226, 174);
  --lightGreenOpacity: rgba(114, 226, 174, 0.1);
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
  font-size: 1rem;
  user-select: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
}

p {
  font-size: 1rem;
  user-select: none;
}

h1,h2,h3 {
  user-select: none;
}

.wrapper {
  max-width: 144rem;
  margin: auto;
  overflow: hidden;
}

.headingContainer {
  position: relative;
  display: grid;
}

.headingBackground {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10rem;
  line-height: 8rem;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.05);
  font-weight: 700;
}

.headingSection {
  font-size: 3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 0 auto 8rem;
  text-align: center;
}

.headingUnderline {
  width: 13rem;
  height: 0.6rem;
  background-color: rgba(114, 226, 174, 0.2);
  border-radius: 4px;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
}

.headingUnderline::after {
  content: "";
  width: 0.6rem;
  height: 0.6rem;
  background-color: var(--lightGreen);
  position: absolute;
  border-radius: 100%;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-name: moveToRight;
}

@keyframes moveToRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(13rem);
  }
}

@media screen and (min-width: 768px) {
  .headingSection {
    font-size: 6rem;
    margin: 0 auto 12rem;
  }
  .mainHeader {
    padding-bottom: 1rem;
  }
  .headingBackground {
    font-size: 16rem;
    line-height: 13rem;
  }

  .headingUnderline {
    width: 22rem;
    height: 1rem;
    border-radius: 99px;
    top: 9rem;
  }

  .headingUnderline::after {
    width: 1rem;
    height: 1rem;
    animation-duration: 2s;
  }

  @keyframes moveToRight {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(22rem);
    }
  }
}

@media screen and (min-width: 1440px) {
  .headingSection {
    font-size: 6rem;
    margin: 0 auto 10rem;
  }

  .mainHeader {
    font-size: 6rem;
    padding-bottom: 2rem;
  }
}
