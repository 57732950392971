.hero {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroContainer {
  margin-bottom: 12rem;
  width: 100%;
}

.heading {
  font-size: 2.6rem;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.heading__special {
  padding-left: 0.8rem;
  color: var(--lightGreen);
}

.list {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  max-width: 300px;
  margin: auto;
}

.image {
  object-fit: cover;
}

.listItem {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  width: 25%;
  text-align: center;
}

.listItemContent {
  display: inline-block;
  position: relative;
}

.listItemContent::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: var(--lightGreen);
  position: absolute;
  top: 4px;
  left: -12px;
}

.imageContainer {
  width: 250px;
  height: 250px;
  display: block;
  border-radius: 100%;
  border: 5px solid var(--lightGreen);
  margin: 0 auto 2rem;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 4.6rem;
    margin-bottom: 1.6rem;
    flex-direction: row;
  }

  .listItem {
    font-size: 1.6rem;
  }

  .list {
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1440px) {
  .heading {
    font-size: 6.6rem;
    margin-bottom: 2.2rem;
  }
  .listItem {
    font-size: 2.4rem;
  }

  .list {
    gap: 3rem;
    max-width: 400px;
  }

  .listItem::before {
    top: 5px;
    left: -20px;
  }

  .imageContainer {
    width: 300px;
    height: 300px;
    border: 6px solid var(--lightGreen);
  }
}
