.portfolio {
    padding: 0 2.4rem;
    margin-bottom: 8rem;
    display: grid;
}

.works {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-bottom: 3rem;
}

.subHeading {
    margin-bottom: 2rem;
    text-align: center;
    font-size: 2rem;
}

.marginFix {
    margin-bottom: 6rem;

}


@media screen and (min-width:525px) {
    .works {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

@media screen and (min-width:768px) {
    .works {
        grid-template-columns: 1fr 1fr;
        gap: 3rem;
        margin-bottom: 5rem;
    }

    .portfolio {
        margin-bottom: 16rem;
    }
    .marginFix {
        margin-bottom: 8rem;
    
    }
}

@media screen and (min-width:1024px) {
    .works {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 3rem;
    }
}

@media screen and (min-width:1440px) {
    .portfolio {
        padding: 0 4.4rem;
        margin-bottom: 14rem;
    }
}