.header {
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;
    left: 0;
    background: #090A0F;
    color: white;
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid #282828;
}

.container {
    padding: 2.4rem;
    max-width: 144rem;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width:1440px) {
    .container {
        padding: 4.4rem;
    }
}